@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* @import '~datatables.net-dt/css/jquery.dataTables.min.css'; */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

:root {
  --card-background: "red";
  --visible-link-with-hover: "#3699FF";
  --text-muted-color: "#B5B5C3";
  --currency-symbol: "#7E8299";
  --Price-text: "#3F4254";
  --view-text: "#0073e9";
  --user-group-pic-border: "#ffffff";
  --user-profile-pic-bg: "#FFE2E5";
  --user-profile-text: "#F64";
  --ask-btn-text: "#3699FF";
  --ask-btn-bg: "#E1F0FF";
  --ask-btn-text-active: "#ffffff";
  --hire-btn-text: "#ffffff";
  --hire-btn-bg: "#3699FF";
  --hire-btn-hover-bg: "#187DE4";
  --hire-btn-hover-border: "#187DE4";
  --user-items-text: "#3F4254";
  --user-description: "#7E8299";
  --progressbar-bg: "#EBEDF3";
  --progressbar-percentage: "#1BC5BD";
}

* {
  font-family: Poppins, Helvetica, "sans-serif";
}

/* .haA-dxq{
  padding:29px 124px;
} */
/* @media screen and (max-width: 992px) {
  .haA-dxq{
    padding:29px 21px !important;
  }
  
} */
body {
  background-color: #edeff7;
  font-size: 13px !important;
  font-weight: 400;
}

.pc-mr-top {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x)); */
}

.pc-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: black;
  position: relative;
  background-color: var(--card-background) !important;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  margin-bottom: 25px;
}

@media (max-width: 991.98px) {

  html,
  body {
    font-size: 12px !important;
  }
}

.makeStyles-wrapper-4 {
  overflow-wrap: anywhere;
}

/* .card-body{
    display: flex;
    align-items: center;
    width: 100%;
} */
.pc-button {
  cursor: pointer;
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid rgba(0, 149, 232, 0.5);
  color: #1bc5bd;
  margin-right: 10px;
  border-radius: 4px;
}

.pc-bg {
  background-color: #1bc5bd !important;
  color: #ffffff !important;
}

.pc-add-button {
  border-color: #1bc5bd;
  margin-bottom: 21px !important;
  padding: 0.55rem 0.75rem !important;
  font-size: 0.925rem;
  border-radius: 0.42rem !important;
  margin-top: 20px;
}

.pc-username {
  font-size: 1.25rem !important;
  color: #181c32 !important;
  font-weight: 500 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pc-username:hover {
  color: var(--visible-link-with-hover) !important;
}

.pc-profile-row {
  flex: 1 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
  margin-left: 2rem !important;
}

.pc-finance {
  padding: 5px 5px;
  flex-direction: column !important;
  display: flex;
  box-sizing: border-box;
  font-weight: 600 !important;
  font-size: 0.925rem !important;
}

@media (max-width: 991.98px) {
  .pc-finance {
    display: flex;
    flex-wrap: wrap;
    font-weight: 600 !important;
  }
}

.pc-img-logo {
  border-radius: 50px;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 7px;
}

.pc-profile-pic {
  border-radius: 50px;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  /* margin-left: 7px; */
}

.pc-personal-detail {
  display: flex;
  align-items: center;
  font-size: 13px !important;
}

@media screen and (max-width: 992px) {
  .pc-personal-detail {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.pc-personal-detail>img {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  /* color:#3F4254 !important; */
}

.pc-personal-detail>span {
  display: flex;
  color: var(--text-muted-color) !important;
  font-weight: 500;
  margin-right: 50px;
}

.pc-personal-detail>span:hover {
  color: var(--visible-link-with-hover) !important;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .pc-personal-detail>span {
    margin-right: 5px !important;
    font-size: 12px !important;
  }
}

.pc-icons:hover {
  color: var(--visible-link-with-hover) !important;
  cursor: pointer;
}

.pc-icons {
  border-radius: 50px;
  width: 20px;
  height: 20px;
}

.pc-currency {
  color: #7e8299 !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.pc-amount {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #3f4254 !important;
}

@media (max-width: 991.98px) {
  .pc-amount {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #3f4254 !important;
  }
}

.pc-view-link {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #0073e9 !important;
}

@media (max-width: 991.98px) {
  .pc-view-link {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #0073e9 !important;
  }
}

/* .linktag{
  color: #0073e9 !important;
  outline:0;
  font-weight:500 !important;
  cursor: pointer;


} */
.pc-column {
  padding: 10px;
}

.pc-user-group {
  margin-left: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

/* .symbolgroup:first-child{
  margin-left:0;
} */
.pc-user-grp-pic {
  position: relative;
  z-index: 0;
  margin-left: -10px;
  border: 2px solid #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.pc-user-grp-pic:hover {
  position: relative;
  z-index: 1;
  margin-left: -10px;
  border: 2px solid #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.pc-imag-tag {
  width: 120px;
  height: 120px;
  background-color: #ffe2e5;
  display: flex;
  border-radius: 0.42rem;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  color: red;
  font-size: 1.5rem !important;
  /* font-weight: 400; */
}

@media screen and (max-width: 992px) {
  .pc-imag-tag {
    width: 50px;
    height: 50px;
    border-radius: 0.42rem;
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
  }
}

.pc-user-detail-column {
  width: 90% !important;
  margin-top: 1.5rem !important;
  /* margin-bottom: 0.5rem !important; */
  margin-left: 18px;
}

@media screen and (max-width: 992px) {
  .pc-user-detail-column {
    margin-top: 2px !important;
    width: 90% !important;
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 600px) {
  .pc-card-active-link {
    flex-direction: column;
  }
}

.pc-card-active-link {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 2px;
}

.pc-user-icon-link {
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
  flex-wrap: wrap;
}

.pc-topbar {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  transition: none !important;
  position: relative;
}

.pc-topbar-icon {
  height: calc(1.5em + 1.65rem + 2px);
  width: calc(1.5em + 1.65rem + 2px);
  cursor: pointer;
  color: #b5b5c3;
  background-color: transparent;
  border-color: transparent;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 0.25rem !important;
  font-weight: normal;
  color: #3f4254;
  text-align: center;
}

.pc-display-flex {
  display: flex;
}

@media screen and (max-width: 992px) {
  .pc-display-flex {
    display: flex;
    margin-bottom: 10px;
  }
}

.pc-edit-del-icon {
  margin-right: 0.5rem;
  padding: 5px 10px;
  font-size: 0.725rem;
  display: inline-block;
  text-align: center;
}

.pc-edit-del-icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .pc-edit-del-icon {
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.42rem;
  }
}

.pc-tick-mark {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  /* margin-top: 1px; */
}

@media screen and (max-width: 992px) {
  .pc-hire-btn {
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.42rem;
  }
}

.pc-user-details {
  display: flex;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .pc-user-details {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(126px, 1fr)) !important;
  }
}

.pc-user-items {
  display: flex !important;
  flex: 1 1 auto !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.pc-user-items-align {
  display: flex !important;
  color: var(--user-items-text) !important;
  flex-direction: column !important;
  box-sizing: border-box;
}

.pc-user-description {
  display: flex;
  transition: none !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  box-sizing: border-box;
}

.pc-inner-description {
  color: var(--user-description) !important;
  font-weight: 400;
  font-size: 13px;
}

.pc-progress-bar {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .pc-progress-bar {
    /* margin-bottom: 5px; */
    margin-top: 10px;
  }
}

.pc-progress-bar-progress {
  width: 200px;
  height: 7px;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: var(--progressbar-bg);
  box-shadow: none;
}

@media screen and (max-width: 992px) {
  .pc-progress-bar-progress {
    width: 100px;
    height: 7px;
    border-radius: 10px;
    margin: 10px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: var(--progressbar-bg);
    box-shadow: none;
  }
}

.pc-percentage {
  background-color: var(--progressbar-percentage) !important;
  width: 50%;
  height: 10px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* --------Profile */
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;
  /* background-color: #ffffff; */
}

.pc-container {
  /* display: flex; */
  /* max-width: 1340px; */
  flex-direction: row !important;
  position: relative !important;
  display: flex !important;
  /* margin-right: auto; */
  /* margin-left: auto; */
}

@media (min-width: 992px) {
  .pc-container {
    padding: 0 25px;
  }
}

.pc-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  display: flex !important;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pc-side-menu {
  flex-shrink: 0 !important;
  flex: 0 0 auto;
}

@media only screen and (max-width: 767.98px) {
  .pc-side-menu {
    display: none;
  }
}

@media only screen and (max-width: 767.98px) {
  .pc-aside-menu {
    z-index: 1001;
    position: fixed;
    top: 78px;
    bottom: 0;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    /* left: -295px; */
    width: 275px;
    overflow: auto;
  }
}

/* @media (min-width: 1200px) {
  .pc-side-menu {
    width: 350px !important;
  }
} */
.pc-menu {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
  box-sizing: border-box;
  display: block;
}

@media (min-width: 992px) {
  .pc-menu {
    margin-left: 2rem !important;
  }
}

.pc-custom-card {
  display: flex;
  align-items: stretch !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;
}

.pc-custom-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.25rem;
}

.pc-items-one {
  display: flex !important;
  line-height: 1.5;
  text-align: left;
  justify-content: flex-end !important;
}

.pc-drop-down-inline {
  display: inline-block;
}

.hamburger-btn {
  height: calc(1.35em + 1.1rem + 2px);
  width: calc(1.35em + 1.1rem + 2px);
  cursor: pointer;
  color: #b5b5c3;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  outline: none !important;
  vertical-align: middle;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  font-weight: normal;
  user-select: none;
}

.hamburger-btn:hover {
  color: #3699ff;
  background-color: #e1f0ff;
}

/* .hamburger-btn:hover ~ .pc-dropdown-menu{
  display: block;
} */
.pc-dropdown-menu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  /* transform: translate3d(-142px, 33px, 0px); */
  transform: translate3d(157px, 83px, 0px);
  right: auto;
  bottom: auto;
  display: none;
  z-index: 96;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.42rem;
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}

[class^="fa-"]:before {
  font-family: Flaticon2;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* font-smoothing: antialiased; */
}

.fa-droplet:before {
  content: "";
}

*::before,
*::after {
  box-sizing: border-box;
}

@media (min-width: 205px) {
  .pc-dropdown-menu {
    width: 175px;
  }
}

.pc-toogle-list {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
  box-sizing: border-box;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  /* padding-inline-start: 40px; */
  text-align: -webkit-match-parent;
}

.pc-toogle-item-link {
  color: #3f4254;
  font-size: 1rem;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 0.75rem;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  list-style: none;
}

.pc-toogle-item-link:hover {
  background-color: #f3f6f9;
  color: #60c2ba !important;
}

.pc-toogle-icon {
  -webkit-box-flex: 0;
  flex: 0 0 2rem;
  line-height: 0;
  margin-left: 10px;
  color: #b5b5c3;
}

.pc-toogle-item {
  margin-bottom: 0.5rem !important;
}

.pc-toogle-text {
  color: #3f4254;
  font-size: 14px;
  font-weight: 400;
  flex-grow: 1;
}

.pc-navi-separator {
  margin: 0;
  padding: 0;
  height: 0;
  display: block;
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important;
}

.pc-items-two {
  align-items: center !important;
  display: flex !important;
  box-sizing: border-box;
  word-wrap: break-word;
}

.pc-user-image {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
  margin-right: 1.25rem !important;
}

@media (min-width: 1400px) {
  .pc-user-image {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
}

.pc-image-label {
  /* background-image: url(/public/300_21.jpg); */
  width: 100px !important;
  height: 100px !important;
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  display: flex;
  /* -webkit-box-align: center; */
  /* -ms-flex-align: center; */
  align-items: center;
  /* -webkit-box-pack: center; */
  /* -ms-flex-pack: center; */
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

@media (min-width: 1400px) {
  .pc-image-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .pc-image-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .pc-image-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .pc-image-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 576px) {
  .pc-image-label {
    width: 50px;
    height: 50px;
  }
}

.pc-success-symbol {
  width: 12px;
  height: 12px;
  /* top: -6px; */
  /* right: -6px; */
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  /* top: 0; */
  background-color: #1bc5bd !important;
  /* right: 0; */
  font-size: 1.25rem;
  color: #b5b5c3;
}

.pc-user-title {
  font-size: 1.25rem !important;
  color: #3f4254 !important;
  font-weight: 600 !important;
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  line-height: 1.5;
  text-align: left;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pc-user-title:hover {
  /* background-color: #F3F6F9; */
  color: #60c2ba !important;
}

.hover-icon:hover {
  color: #60c2ba !important;
}

.pc-text-muted {
  color: #b5b5c3 !important;
  box-sizing: border-box;
  display: block;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pc-user-btn {
  margin-top: 0.5rem !important;
  box-sizing: border-box;
  display: block;
}

.pc-chat-btn {
  cursor: pointer;
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
  transition: none !important;
  outline: none !important;
  vertical-align: middle;
  font-weight: 500 !important;
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  box-shadow: none;
  display: inline-block;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
}

.pc-chat-btn:hover {
  color: #ffffff;
  background-color: #187de4 !important;
  border-color: #187de4 !important;
}

.pc-follow-btn {
  cursor: pointer;
  color: #ffffff;
  background-color: #1bc5bd;
  border-color: #1bc5bd;
  transition: none !important;
  outline: none !important;
  vertical-align: middle;
  font-weight: 500 !important;
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  box-shadow: none;
  display: inline-block;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
}

.pc-symbol-badge {
  width: 30px;
  height: 30px;
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  background-color: #1bc5bd !important;
  font-size: 1.25rem;
  color: #b5b5c3;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

.pc-symbol-badge>svg {
  font-size: 25px !important;
  position: absolute !important;
  color: white !important;
  padding: 4px !important;
}

.pc-items-three {
  padding-bottom: 2.25rem !important;
  padding-top: 2.25rem !important;
  box-sizing: border-box;
  word-wrap: break-word;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  text-align: left;
}

.pc-user-personal-detail {
  margin-bottom: 0.5rem !important;
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  word-wrap: break-word;
  color: #3f4254;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  line-height: 1.5;
  text-align: left;
}

#pc-detail-value:hover {
  color: #60c2ba !important;
}

.pc-detail {
  font-weight: 500 !important;
  margin-right: 0.5rem !important;
  word-wrap: break-word;
  color: #3f4254;
  font-size: 13px !important;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pc-detail-value {
  color: #b5b5c3 !important;
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  line-height: 1.5;
}

.pc-items-four {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  transition: none !important;
  box-sizing: border-box;
  word-wrap: break-word;
  color: #3f4254;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

.pc-navi-items-four {
  padding: 0;
  display: block;
  list-style: none;
  margin-bottom: 0.5rem !important;
  box-sizing: border-box;
  word-wrap: break-word;
  color: #3f4254;
}

.pc-navi-link {
  /* background-color: #F3F6F9; */
  margin-right: 0.5rem !important;
  color: #3f4254;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  padding-bottom: 1rem !important;
  outline: 0 !important;
  cursor: pointer;
  list-style: none;
  word-wrap: break-word;
  border-radius: 0.42rem;
  list-style: none;
}

.pc-actived {
  background-color: #f3f6f9;
  color: #60c2ba !important;
}

.pc-navi-link:hover {
  background-color: #f3f6f9;
  color: #60c2ba !important;
}

.MuiSvgIcon-root:hover {
  color: #60c2ba !important;
}

.mui-icon {
  margin-right: 0rem !important;
  color: #60c2ba !important;
}

.contact-color {
  margin-right: 0.5rem;
}

a {
  text-decoration: none !important;
}

.pc-navi-text {
  font-weight: 500;
  font-size: 14px;
  flex-grow: 1;
  transition: none !important;
  box-sizing: border-box;
  cursor: pointer;
  list-style: none;
  font-family: Poppins, Helvetica, "sans-serif";
  line-height: 1.5;
  margin-left: 1rem !important;
}

.pc-navi-label {
  line-height: 0;
  margin-left: 0.75rem;
  cursor: pointer;
}

.pc-label-count {
  border-radius: 0.42rem;
  color: #f64e60;
  background-color: #ffe2e5;
  padding: 0;
  margin: 0;
  display: inline-flex;
  -webkit-box-pack: center;
  align-items: center;
  height: 20px;
  width: 20px;
  font-size: 0.8rem;
  font-weight: 500 !important;
  line-height: 0;
  cursor: pointer;
  word-wrap: break-word;
  justify-content: center;
}

.pc-label-btn {
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
  color: #3699ff;
  background-color: #e1f0ff;
  margin: 0;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 20px;
  font-size: 0.8rem;
  font-weight: 500 !important;
  line-height: 0;
  cursor: pointer;
  list-style: none;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pc-top-menu {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12.5px;
  margin-left: -12.5px;
  color: #3f4254;
  line-height: 1.5;
  text-align: left;
}

.pc-bottom-menu {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;
  box-sizing: border-box;
  padding: 2rem 2.25rem;
}

.pc-column-lg {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
  box-sizing: border-box;
  display: block;
}

@media (min-width: 992px) {
  .pc-column-lg {
    /* flex: none; */
    /* max-width: none ; */
    position: relative;
    width: 100%;
    padding-right: 12.5px;
    padding-left: 12.5px;
    box-sizing: border-box;
    display: block;
  }
}

.pc-custom-card-right {
  height: calc(100% - 25px);
  display: flex;
  -webkit-box-align: stretch !important;
  align-items: stretch !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
  margin-bottom: 25px;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;
}

.pc-menu-card-header {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
  border: 0 !important;
  padding: 2rem 2.25rem;
  margin-bottom: 0;
  word-wrap: break-word;
}

.pc-menu-card-body {
  padding: 2rem 2.25rem;
  transition: none !important;
  padding-top: 0.5rem !important;
  flex: 1 1 auto;
  min-height: 1px;
  box-sizing: border-box;
  word-wrap: break-word;
}

.pc-menu-card-title {
  font-size: 18px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
  color: #181c32 !important;
  font-weight: 600 !important;
  line-height: 1.2;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  -webkit-box-direction: normal;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pc-tool-bar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  transition: none !important;
  box-sizing: border-box;
  word-wrap: break-word;
}

.pc-menu-card-data {
  margin-bottom: 2.5rem !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  display: flex !important;
  box-sizing: border-box;
}

.pc-menu-card-img {
  background-image: url(/public/300_21.jpg);
  display: inline-block;
  position: relative;
  border-radius: 0.42rem;
  margin-right: 1rem !important;
  flex-shrink: 0 !important;
  box-sizing: border-box;
  word-wrap: break-word;
}

.pc-menu-card-img-label {
  height: 60px;
  width: 90px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

@media (min-width: 1400px) {
  .pc-menu-card-img-label {
    height: 50px;
    width: 75px;
  }
}

@media (min-width: 1200px) {
  .pc-menu-card-img-label {
    height: 50px;
    width: 75px;
  }
}

@media (min-width: 992px) {
  .pc-menu-card-img-label {
    height: 50px;
    width: 75px;
  }
}

@media (min-width: 768px) {
  .pc-menu-card-img-label {
    height: 50px;
    width: 75px;
  }
}

@media (min-width: 576px) {
  .pc-menu-card-img-label {
    height: 50px;
    width: 75px;
  }
}

/* .card-one {
  background-image: url(/public/one.jpg);
}
.card-two {
  background-image: url(/public/two.jpg);
}
.card-three {
  background-image: url(/public/three.jpg);
}
.card-four {
  background-image: url(/public/four.jpg);
}
.card-five {
  background-image: url(/public/five.jpg);
} */
.pc-menu-card-text {
  padding-right: 0.75rem !important;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  flex-grow: 1 !important;
  flex-direction: column !important;
  display: flex !important;
  box-sizing: border-box;
}

.pc-menu-card-det {
  font-size: 14px;
  color: #3f4254 !important;
  font-weight: 600 !important;
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.pc-menu-card-text-muted {
  transition: none !important;
  font-size: 12px;
  color: #b5b5c3 !important;
  font-weight: 500 !important;
  margin-top: 0.25rem !important;
  box-sizing: border-box;
  -webkit-box-direction: normal !important;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
}

.pc-menu-card-item {
  color: #60c2ba !important;
  font-weight: 500 !important;
  box-sizing: border-box;
  font-size: 12px;
}

.pc-menu-card-price {
  transition: none !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  align-items: center !important;
  display: flex !important;
}

@media (min-width: 992px) {
  .pc-menu-card-price {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.pc-column-text-right {
  text-align: right !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  display: flex !important;
  box-sizing: border-box;
}

.pc-column-text-price {
  font-size: 18px !important;
  color: #3f4254 !important;
  font-weight: 600 !important;
  text-align: right !important;
  word-wrap: break-word;
}

.pc-column-text {
  font-size: 0.925rem;
  color: #b5b5c3 !important;
  font-weight: 600 !important;
  box-sizing: border-box;
  text-align: right !important;
  word-wrap: break-word;
}

.pc-menu-card-items {
  margin-bottom: 1.5rem !important;
  box-sizing: border-box;
}

.pc-item-align {
  -webkit-box-align: center !important;
  align-items: center !important;
  flex-grow: 1 !important;
  display: flex !important;
  word-wrap: break-word;
}

.pc-item-checkbox {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  margin-right: 1rem !important;
  flex-shrink: 0 !important;
  box-sizing: border-box;
}

.pc-item-checkbox>span {
  height: 24px;
  width: 24px;
  background-color: #ebedf3;
  border: 1px solid transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0.42rem;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
}

.pc-item-checkbox>span:after {
  width: 6px;
  height: 12px;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
  margin-top: -2px;
}

.pc-notification-items {
  width: 100% !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  display: flex !important;
  word-wrap: break-word;
}

.pc-notification-item {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  width: 75% !important;
  flex-direction: column !important;
  display: flex !important;
  box-sizing: border-box;
}

.pc-notification-label {
  /* width: auto; */
  padding: 0.9rem 0.75rem;
  height: 24px;
  width: auto;
  font-size: 0.9rem;
  border-radius: 0.42rem;

  margin: 0;
  display: inline-flex;
  /* -webkit-box-pack: center; */
  align-items: center;
  font-weight: 500 !important;
  padding-bottom: 1rem !important;
  box-sizing: border-box;
  justify-content: center;
}

.label-blue {
  color: #3699ff;
  background-color: #e1f0ff;
}

.label-yellow {
  color: #ffa800;
  background-color: #fff4de;
}

.label-success {
  color: #1bc5bd;
  background-color: #c9f7f5;
}

.label-reject {
  color: #f64e60;
  background-color: #ffe2e5;
}

.label-progress {
  color: #ffa800;
  background-color: #fff4de;
}

.pc-iteam-name {
  font-size: 14px;
  color: #3f4254 !important;
  font-weight: 500 !important;
  margin-bottom: 0.25rem !important;
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.pc-iteam-name:hover {
  color: #60c2ba !important;
}

.pc-checkbox-active {
  position: absolute;
  z-index: -1;
  opacity: 0;
  box-sizing: border-box;
  padding: 0;
  overflow: visible;
  margin: 0;
}

.pc-bottom-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.bottom-menu-body {
  padding: 2rem 2.25rem;
  margin-top: -0.75rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.pc-bottom-card-title {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
  display: flex;
  margin: 0.5rem;
  margin-left: 0;
  align-items: flex-start !important;
  flex-direction: column !important;
  line-height: 1.2;
}

.pc-bottom-card-text {
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
  font-size: 1.275rem;
  color: #181c32 !important;
  font-weight: 600 !important;
  line-height: 1.2;
}

.pc-bottom-card-toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  transition: none !important;
  box-sizing: border-box;
  word-wrap: break-word;
}

.pc-nav-pills {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.pc-nav-items {
  margin-right: 0.25rem;
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: none;
}

.pc-nav-link {
  color: #b5b5c3;
  display: flex;
  align-items: center;
  border-radius: 0.42rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  outline: 0 !important;
  background-color: transparent;
}

.bg-clr {
  color: #ffffff !important;
  background-color: #3699ff;
}

.pc-mt-5 {
  margin-top: 1.25rem !important;
}

.pc-tab-content>.pc-active {
  display: block;
}

.pc-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  /* text-align: left; */
  /* line-height: 1.5; */
}

.pc-bottom-menu-table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3f4254;
  background-color: transparent;
  /* border-collapse: collapse; */
}

.pc-bottom-menu-table th {
  border-top: 0;
  vertical-align: middle;
  font-weight: 600;
  font-size: 1rem;
  border-bottom-width: 1px;
  border: 0;
}

.w-50px {
  width: 50px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-120px {
  min-width: 120px !important;
}

.min-w-160px {
  min-width: 160px !important;
}

.p-0 {
  padding: 0 !important;
}

.bottom-menu-table td {
  vertical-align: middle;
  border: 0;
}

.pc-table-symbol-img {
  /* vertical-align: middle; */
  border: 0;
  /* transition: none !important; */
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.pc-table-symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
  margin-right: 20px;
}

.pc-symbol-label {
  background-color: #f3f6f9;
  color: #7e8299;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

@media (min-width: 1400px) {
  .pc-symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .pc-symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .pc-symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .pc-symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 576px) {
  .pc-symbol-label {
    width: 50px;
    height: 50px;
  }
}

.pc-table-img {
  height: 50% !important;
  align-self: center !important;
  vertical-align: middle;
  border-style: none;
  color: #7e8299;
}

.pc-pl-0 {
  padding-left: 0 !important;
  text-align: left !important;
}

.pc-table-text {
  font-size: 16px;
  color: #3f4254 !important;
  font-weight: 600 !important;
  margin-bottom: 0.25rem !important;
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
}

.pc-table-text:hover {
  color: #60c2ba !important;
}

.pc-text-right {
  /* vertical-align: middle; */
  text-align: right !important;
}

.pc-add-margin {
  margin-bottom: 1.5rem !important;
}

.pc-pr-0 {
  padding-right: 0 !important;
}

.pc-symbol-btn {
  height: calc(1.35em + 1.1rem + 2px);
  width: calc(1.35em + 1.1rem + 2px);
  cursor: pointer;
  color: #7e8299;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none !important;
  vertical-align: middle;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  text-align: center;
  font-weight: normal;
  user-select: none;
  border: 1px solid transparent;
}

.margin {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.pc-email-text {
  font-weight: 600 !important;
}

.pc-text-muteds {
  color: #b5b5c3 !important;
}

.pc-mt-3 {
  margin-top: 0.75rem !important;
}

/* personal info */

.pc-profile-custom-card {
  display: flex;
  align-items: stretch !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
  transition: none !important;
  position: relative;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;
  box-sizing: border-box;
  color: #3f4254;
  /* margin-left: 50px; */
  /* max-width: 1000px; */
  width: -webkit-fill-available;
  margin-bottom: 2rem;
  min-height: 95vh;
}

.pc-profile-card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  background-color: transparent;
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
  transition: none !important;
  padding: 2rem 2.25rem;
  margin-bottom: 0;
  word-wrap: break-word;
  border-bottom: 1px solid #ebedf3;
}

.pc-padding {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}

.pc-profile-card-form {
  box-sizing: border-box;
  display: block;
  margin-top: 0em;
}

.pc-profile-card-title {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
  margin: 0.5rem;
  margin-left: 0;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.pc-card-label {
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
  font-size: 1.275rem;
}

.pc-text-dark {
  color: #181c32 !important;
  font-weight: 600 !important;
  line-height: 1.2;
  box-sizing: border-box;
  font-size: 16px;
  display: block !important;
  text-align: left !important;
}

.pc-profile-card-body {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 2rem 2.25rem;
  /* box-sizing: border-box; */
}

.pc-profile-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12.5px;
  /* margin-left: -12.5px; */
  word-wrap: break-word;
}

.pc-col {
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

.pc-col-flex {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 991px) {
  .pc-col-flex {
    flex: none;
    max-width: none;
  }
}

.pc-col-flex1 {
  flex: 0 0 35%;
  max-width: 35%;
}

@media (max-width: 991px) {
  .pc-col-flex1 {
    flex: none;
    max-width: none;
  }
}

.pc-lbl {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.pc-profile-text {
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

@media (min-width: 1200px) {
  .pc-profile-text {
    flex: 0.5 0 50%;
    max-width: 50%;
  }
}

.pc-profile-text1 {
  position: relative;
  width: 80%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

@media (min-width: 1200px) {
  .pc-profile-text1 {
    flex: 0.5 0 50%;
    /* max-width: 50%; */
  }
}

@media (min-width: 992px) {
  .pc-profile-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0.5 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .pc-profile-text1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0.5 0 50%;
    max-width: 50%;
  }
}

.pc-form-group {
  margin-bottom: 1.75rem;
}

.pc-col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  /* font-size: inherit; */
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  color: #3f4254;
}

.pc-profile-img {
  position: relative;
  display: inline-block;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(/public/300_21.jpg); */
}

.pc-image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.pc-pencil-icon {
  box-shadow: 0px 9px 16px 0px rgb(24 28 50 / 25%) !important;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #ffffff;
  border-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none !important;
  vertical-align: middle;
  color: #b5b5c3 !important;
  font-size: 0.75rem !important;
}

.form-text {
  margin-top: 0.25rem !important;
}

.pc-btn-close {
  bottom: -5px !important;
  top: 99px !important;
}

.pc-pencil-icon:hover {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}

.pc-pencil-icon:hover~.tooltip {
  font-size: large;
}

.pc-form-control.pc-form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: none !important;
}

.pc-form-control-lg {
  /* height: calc(1.5em + 1.65rem + 2px); */
  padding: 0.825rem 1.42rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.pc-form-control {
  display: block;
  width: 100%;
  font-weight: 500;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  box-shadow: none;
  overflow: visible;
  font-family: Poppins, Helvetica, "sans-serif";
}

input[type="text"]:focus {
  border-color: transparent;
}

.pc-input-group {
  border-radius: 0.42rem;
  align-items: center;
  background-color: #f3f6f9;
  transition: none !important;
  position: relative;
  /* display: flex; */
  flex-wrap: wrap;
  /* width: 100%; */
}

.pc-input-group-prepend {
  margin-right: -1px;
  display: flex;
}

.pc-input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 400;
  color: #3f4254;
  text-align: center;
  white-space: nowrap;
}

.pc-input-group-form-control {
  padding-left: 0 !important;
  border: 0;
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  display: block;
}

.space {
  padding-left: 1.42rem !important;
}

.icon-color {
  color: #b5b5c3 !important;
}

.icon-color:hover {
  cursor: pointer;
}

input[type="file"] {
  /* display: none; */
  font-size: 1rem;
  font-weight: 500;
  font-family: Poppins, Helvetica, "sans-serif";
}

/* contact info */
.primary {
  color: #1bc5bd !important;
}

.sucess {
  color: #60c2ba !important;
}

.danger {
  color: #f64e60 !important;
}

.add-space {
  margin-bottom: 1.25rem !important;
}

.col-9 {
  flex: 0 0 auto;
  width: 60%;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: var(--bs-gutter-y);
}

.pc-alert-custom {
  background-color: #ffe2e5;
  border-color: transparent;
  display: flex;
  align-items: stretch;
  padding: 1.5rem 2rem;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.42rem;
  transition: opacity 0.15s linear;
}

.pc-alert-icon {
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0 0;
  color: red !important;
}

.pc-alert-close {
  padding: 0 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 0;
}

/* .pc-align-address-text {
  text-align: end ;
} */
.pc-card-toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}

.pc-edit-btn {
  color: #ffffff;
  background-color: #1bc5bd;
  border-color: #1bc5bd;

  outline: none !important;
  vertical-align: middle;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  box-shadow: none;
  display: inline-block;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  border-radius: 0.42rem;
  text-transform: none;
  margin: 0;
  font-family: Poppins, Helvetica, "sans-serif";
}

.btn-curser-show {
  cursor: pointer;
}

.btn-curser-block {
  cursor: not-allowed;
}

.btn-success {
  background-color: #0bb7af !important;
  border-color: #0bb7af !important;
}

.btn-secondary {
  background-color: #e4e6ef !important;
  border-color: #e4e6ef !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.pc-text-end {
  text-align: right !important;
}

.pc-text-end-usercard {
  text-align: right !important;
  display: flex;
}

@media screen and (max-width: 613px) {
  .pc-text-end-usercard {
    /* background-color: yellow; */
    /* display: flex;
    flex-direction: column;
    object-fit: cover; */

  }
}

.pc-page-card {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;
}

.pc-page-card-body {
  padding: 2rem 2.25rem;
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
  flex: 1 1 auto;
}

.pc-page-card-flex {
  align-items: center !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  display: flex !important;
}

.pc-pagination-part {
  flex-wrap: wrap !important;
  display: flex !important;
}

.pc-record-part {
  align-items: center !important;
  display: flex !important;
}

.pc-arrow-btn {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}

.pc-btn-flex {
  height: 2rem !important;
  width: 2rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  font-size: 0.925rem;
  border-radius: 0.42rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
}

.pc-arrow-btn:disabled {
  pointer-events: none;
  opacity: 0.6;
  box-shadow: none;
}

.number-btn {
  color: #3f4254;
  cursor: pointer;
  margin-left: 2px;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  border-radius: 0.42rem;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.number-btn:hover {
  color: #ffffff !important;
  background-color: #60c2ba !important;
  border-color: #60c2ba !important;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  box-shadow: none;
  outline: none !important;
  vertical-align: middle;
  /* margin-right: 10px; */
}

.pc-active {
  color: #ffffff !important;
  background-color: #60c2ba !important;
  border-color: #60c2ba !important;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  box-shadow: none;
  outline: none !important;
  vertical-align: middle;
}

.d-none {
  /* display: none; */
  background: transparent;
}

.pc-subheader {
  /* position: fixed; */
  height: 85px;
  top: 65px;
  /* left: 0; */
  right: 0;
  z-index: 95;
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
  margin: 0;
  display: flex;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
  align-items: center;
  border-radius: 0.625rem;
  margin-bottom: 10px;
}

.pc-subheader1 {
  /* position: fixed; */
  /* height: 85px; */
  top: 65px;
  /* left: 0; */
  right: 0;
  z-index: 95;
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
  margin: 0;
  display: flex;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
  align-items: center;
  border-radius: 0.625rem;
  margin-bottom: 10px;
}

.pc-subhead-container {
  padding: 0 25px;
  transition: none !important;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  display: flex !important;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.pc-subhead-container1 {
  padding: 0 25px;
  transition: none !important;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  display: flex !important;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width:600px) {
  .pc-subhead-container1 {
    flex-direction: column;
  }

}

.pc-flex-align-item-center {
  align-items: center !important;
  display: flex !important;
}

.pc-flex-align-item-center1 {
  align-items: center !important;
  display: flex !important;
  /* flex-direction: column; */
}

@media screen and (max-width: 475px) {
  .pc-flex-align-item-center1 {
    /* background-color: yellow; */
    align-items: center !important;
    display: flex !important;
    flex-wrap: wrap-reverse;

  }
}

.pc-flex-wrap {
  flex-wrap: wrap !important;
  margin-right: 0.25rem !important;
}

.pc-subhead-container~.haA-dxq {
  padding: 0px !important;
}

.pc-grey-clr {
  color: #b5b5c3 !important;
  margin-right: 0.5rem !important;
}

/* .pc-text-danger{
  color: red;
} */

/* .text-danger{
  color: #eb453d !important;
} */
.btn.btn-danger {
  color: #ffffff;
  background-color: #f64e60;
  border-color: #f64e60;
}

.custom-alert-head i {
  font-size: 5em;
  margin-left: 1px;
  position: absolute;
  top: -36px;
  background: white;
  border-radius: 50%;
}

.pc-form-btn {
  flex-direction: row;
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 0.42rem;
  /* align-items: center;
    margin-bottom: 25px; */
}

.pc-btn-center {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.display-none {
  display: none;
}

.sort-icon:hover {
  color: #000000 !important;
}

.loding {
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.356);
}

#dialog-style {
  color: red !important;
  padding: 8px 8px !important;
}

.pc-active-icon {
  color: #60c2ba !important;
}

.pc-form-select {
  display: block;
  width: 100%;
  /* padding: 0.65rem 1rem 0.65rem 1rem; */
  font-weight: 500;
  line-height: 1.5;
  color: #3f4254;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #e4e6ef;
  /* border-radius: 0.42rem; */
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  background-color: #f3f6f9;
}

.show-dropdown-icon {
  background-image: url(/public/downarrow.png);
}

.curser-disable {
  cursor: not-allowed;
}

.pc-form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 0.825rem;
  font-size: 1rem;
  border-radius: 0.42rem;
  outline: none;
}

.pc-otp_screen {
  flex: 1 0 auto;
  text-align: center !important;
  padding: 2.5rem !important;
  justify-content: center !important;
  flex-direction: column !important;
  display: flex !important;
  box-sizing: border-box;
}

@media (min-width: 992px) {
  .pc-otp_screen {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

.pc-verification {
  padding-top: 2.5rem !important;
}

@media (min-width: 992px) {
  .pc-verification {
    padding-top: 2.5rem !important;
  }
}

.pc-font-size-h1 {
  font-size: 2rem !important;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .pc-font-size-h1 {
    font-size: 1.5rem;
  }
}

.fw-bold {
  font-weight: 500 !important;
}

.spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.pc-hyperlink {
  margin-left: 0.5rem;
  color: #0073e9 !important;
  cursor: pointer;
}

.pc-otp_screen {
  background: #ffffff;
}

.pc-search {
  border-bottom: 1px solid #60c2ba !important;
  border: none;
  color: #3f4254;
  display: block;
  width: 50%;
  font-weight: 400;
  /* padding: 0.825rem 1.42rem; */
  font-size: 1.08rem;
  line-height: 1.5;
  margin-top: 22px;
}

.pc-search:focus {
  outline: none;
}

.pc-topbar-search {
  /* height: calc(1.5em + 1.65rem + 2px); */
  /* width: calc(1.5em + 1.65rem + 2px); */
  cursor: pointer;
  color: #b5b5c3;
  background-color: transparent;
  border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: end;
  padding: 0;
  margin-right: 0.25rem !important;
  font-weight: normal;
  text-align: center;
}

.col-lg-3 {
  flex: 0 0 auto;
  width: 25%;
}

@media (min-width: 992px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 32%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 23%;
  }
}

.grid-view {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  /* row-gap: 1%; */
  column-gap: 1%;
  padding: 0 5px;
}

@media (max-width: 576px) {
  .grid-view {
    grid-template-columns: 1fr;
    /* row-gap: 1%; */
    column-gap: 1%;
  }
}

@media (min-width: 768px) {
  .grid-view {
    grid-template-columns: 1fr 1fr;
    /* row-gap: 1%; */
    column-gap: 1%;
  }
}

@media (min-width: 992px) {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr;
    /* row-gap: 1%; */
    column-gap: 1%;
  }
}

@media (min-width: 1200px) {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr;
    /* row-gap: 1%; */
    column-gap: 1%;
  }
}

.pc-mr-top>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 992px) {
  .pc-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media (min-width: 768px) {
  .pc-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.card.card-custom.card-stretch.gutter-b {
  height: calc(100% - 25px);
}

.mr-2 {
  margin: 2rem;
}

.mr-1p {
  margin: 1rem;
}

.card.card-custom.card-stretch {
  display: flex;
  align-items: stretch !important;
  flex-direction: column;
  height: 100%;
}

.card.card-custom {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
}

.gutter-b {
  margin-bottom: 25px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0.42rem;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

.card.card-custom>.card-body {
  padding: 2rem 2.25rem;
}

.pt-4 {
  padding-top: 1rem !important;
}

.flex-column {
  flex-direction: column !important;
}

.d-flex {
  display: flex !important;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2rem 2.25rem;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.dropdown-inline {
  display: inline-block;
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.btn.btn-hover-light-primary.btn-clean {
  border: 0 !important;
}

/* .fa-edit:hover{
  color: #3F4254;
    border-color: #F1FAFF;
     background-color: #F1FAFF;
} */
.btn.btn-sm.btn-light-danger.btn-hover-light.btn-icon.me-2.mb-2.mt-2 :hover {
  color: #3f4254;
}

.btn.btn-icon.btn-sm,
.btn-group-sm>.btn.btn-icon {
  height: calc(1.5em + 1.1rem + 2px);
  width: calc(1.5em + 1.1rem + 2px);
}

.btn.btn-clean {
  color: #b5b5c3;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.3rem + 2px);
  width: calc(1.5em + 1.3rem + 2px);
}

.btn:not(.btn-text) {
  cursor: pointer;
}

.btn.btn-sm .svg-icon i,
.btn-group-sm>.btn .svg-icon i {
  height: 16px;
  width: 16px;
}

.svg-icon.svg-icon-md i {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.btn .svg-icon i {
  height: 18px;
  width: 18px;
}

.svg-icon i {
  height: 1.5rem;
  width: 1.5rem;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) .btn {
  outline: none !important;
  vertical-align: middle;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.42rem;
}

.btn.btn-icon .svg-icon {
  margin: 0;
  padding: 0;
}

.btn.btn-sm .svg-icon,
.btn-group-sm>.btn .svg-icon {
  margin-right: 0.35rem;
}

.btn .svg-icon {
  margin-right: 0.5rem;
}

.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.5;
  color: #3f4254;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  border-radius: 0.42rem;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.makeStyles-wrapper-1 {
  overflow-wrap: anywhere;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol.symbol-light-danger .symbol-label {
  background-color: #ffe2e5;
  color: #f64e60;
}

@media (min-width: 1400px) {
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .symbol.symbol-lg-120 .symbol-label {
    width: 120px;
    height: 120px;
    font-size: 41px;
  }
}

.symbol.symbol-50>img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}

@media (min-width: 992px) {
  .symbol.symbol-lg-120>img {
    width: 100%;
    max-width: 120px;
    height: 120px;
  }
}

.me-1 {
  margin-right: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .me-lg-8 {
    margin-right: 2rem !important;
  }
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}

.symbol.symbol-circle {
  border-radius: 50%;
}

.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}

.symbol.symbol-primary .symbol-label {
  background-color: #3699ff;
  color: #ffffff;
}

.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}

.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

.symbol .symbol-label {
  width: 50px;
  height: 50px;
}

@media (min-width: 992px) {
  .symbol.symbol-lg-75 .symbol-label {
    width: 75px;
    height: 75px;
  }
}

@media (min-width: 1400px) {
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 576px) {
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
}

.font-size-h3 {
  font-size: 1.5rem !important;
}

a.text-hover-primary,
.text-hover-primary {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.font-size-h4 {
  font-size: 1.25rem !important;
}

.text-dark {
  color: #181c32 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

a,
button {
  outline: 0 !important;
}

.text-dark-75 {
  color: #3f4254;
}

.fw-bolder {
  font-weight: 600 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.btn.btn-light-warning {
  color: #ffa800;
  background-color: #fff4de;
  border-color: transparent;
}

.btn.btn-light-primary {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}

.btn.btn-light-gray {
  color: #aeaeae;
  background-color: #d5d5d5;
  border-color: transparent;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.3rem + 2px);
  width: calc(1.5em + 1.3rem + 2px);
}

.btn.btn-pill {
  border-radius: 2rem;
}

.btn.btn-light-danger {
  color: #f64e60;
  background-color: #ffe2e5;
  border-color: transparent;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-4 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

a.text-hover-primary:hover,
.text-hover-primary:hover {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  color: #60c2ba !important;
}

a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.text-muted {
  color: #b5b5c3 !important;
}

.modal.modal-sticky.modal-sticky-bottom-right {
  padding: 0 !important;
  max-width: 650px;
  height: auto;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 25px;
  right: 25px;
  margin: 0;
  box-shadow: 0px 0px 60px -15px rgb(0 0 0 / 20%);
  border-radius: 0.42rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog {
  position: static;
  max-width: 650px;
  width: auto;
  margin: 0;
  border-radius: 0.42rem;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog {
  position: relative;
  /* width: auto; */
  margin: 0.5rem;
  pointer-events: none;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}

.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog .modal-content {
  border: 0;
  border-radius: 0.42rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.42rem;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 10%);
  outline: 0;
}

@media (min-width: 576px) {
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);
  }
}

.card.card-custom>.card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

.card-header:first-child {
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.card-header {
  padding: 1rem 2.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedf3;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.label.label-sm.label-dot {
  line-height: 4px;
  min-height: 4px;
  min-width: 4px;
  height: 4px;
  width: 4px;
}

.label.label-sm {
  height: 16px;
  width: 16px;
  font-size: 0.75rem;
}

.label.label-dot {
  line-height: 6px;
  min-height: 6px;
  min-width: 6px;
  height: 6px;
  width: 6px;
}

.label.label-dot {
  display: inline-block;
  font-size: 0 !important;
  vertical-align: middle;
  text-align: center;
}

.label.label-success {
  color: #ffffff;
  background-color: #1bc5bd;
}

.label {
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: #ebedf3;
  color: #3f4254;
  font-weight: 400;
  height: 20px;
  width: 20px;
  font-size: 0.8rem;
}

.font-size-sm {
  font-size: 0.925rem;
}

.mesg-card {
  overflow-x: auto;
  height: 55vh;
}

.card.card-custom>.card-body {
  padding: 2rem 2.25rem;
}

.scroll.scroll-pull {
  padding-right: 12px;
  margin-right: -12px;
}

.scroll {
  position: relative;
  overflow: hidden;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.align-items-start {
  align-items: flex-start !important;
}

a.text-hover-primary,
.text-hover-primary {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.font-size-h6 {
  font-size: 1.175rem !important;
}

.symbol.symbol-40>img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}

.symbol.symbol-circle>img {
  border-radius: 50%;
}

.symbol>img {
  display: flex;
  border-radius: 0.42rem;
}

@media (min-width: 1200px) {
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 576px) {
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

.bg-light-success {
  background-color: #c9f7f5 !important;
}

.max-w-400px {
  max-width: 400px !important;
}

.font-size-lg {
  font-size: 1.08rem;
}

.text-dark-50 {
  color: #7e8299 !important;
}

.max-w-400px {
  max-width: 400px !important;
}

.rounded {
  border-radius: 0.42rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.bg-light-primary {
  background-color: #e1f0ff !important;
}

.card.card-custom>.card-footer {
  background-color: transparent;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.42rem - 1px) calc(0.42rem - 1px);
}

.card-footer {
  padding: 1rem 2.25rem;
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
}

textarea.form-control {
  min-height: calc(1.5em + 1.3rem + 2px);
}

.p-0 {
  padding: 0 !important;
}

.border-0 {
  border: 0 !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

textarea {
  resize: vertical;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: inherit;
  line-height: inherit;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.btn.btn-primary {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}

.btn-primary {
  color: #000000;
  background-color: #3699ff;
  border-color: #3699ff;
  box-shadow: none;
}

.btn.btn-diff-clr {
  color: #ffffff;
  background-color: #1bc5bd !important;
  border-color: #1bc5bd;
}

.btn-diff-clr {
  color: #000000;
  background-color: #1bc5bd;
  border-color: #1bc5bd;
  box-shadow: none;
}

.btn-red-clr {
  color: #ffffff;
  background-color: #ff767a !important;
  border-color: #ff767a;
  box-shadow: none;
}

.btn-gray-clr {
  color: #9e9e9e;
  background-color: #c2c8c6 !important;
  border-color: #c2c8c6;
  box-shadow: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

a,
button {
  outline: 0 !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-end {
  text-align: right !important;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
  color: #3699ff;
  background-color: #f3f6f9;
  border-color: transparent;
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 23%;
  }
}

.label.label-lg.label-inline {
  width: auto;
}

.label.label-inline.label-lg {
  padding: 0.3rem 0.75rem;
}

.label.label-lg {
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
}

.label.label-inline {
  width: auto;
}

.label.label-inline {
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}

.label.label-light-warning {
  color: #ffa800;
  background-color: #fff4de;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.05);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  width: 300px;
  padding: 10px 0;
}

.react-confirm-alert-body {
  font-family: Poppins, Helvetica, "sans-serif";
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #666;
}

.custom-alert-head {
  display: flex;
  justify-content: center;
  position: relative;
}

.mr-lg {
  margin-top: 30px;
}

.cr-w {
  color: #fff !important;
}

.text-danger {
  color: #f64e60 !important;
}

.custom-alert-head>i {
  font-size: 5em;
  margin-left: 1px;
  position: absolute;
  top: -36px;
  background: white;
  border-radius: 50%;
}

.la,
.las {
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

input:focus {
  outline: none;
  /* text-align: center; */
}

.react-code-input>input:focus {
  outline: none;
  border: 1px solid #006fff !important;
  caret-color: #006fff !important;
}

textarea:focus {
  outline: none;
}

.table-stripped {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}

.flex-root {
  flex: 1 1;
  -ms-flex: 1 0 0px;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bgi-position-y-bottom {
  background-position-y: bottom;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}

.bgi-size-contain {
  background-size: contain;
}

@media (min-width: 992px) {
  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

.mr-t-15 {
  margin-top: 15rem;
}

.mr-b-20 {
  margin-bottom: 32rem;
}

.bg-white {
  background-color: white !important;
}

.position-relative {
  position: relative !important;
}

.flex-row-fluid {
  flex: 1 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
}

/* .login.login-1 .login-aside {
  width: 600px;
} */

@media (max-width: 1200px) {
  .login.login-1 .login-aside {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .ms-lg-8 {
    margin-left: 1rem !important;
  }
}

@media (max-width: 992px) {
  .ms-lg-8 {
    margin-left: 1rem !important;
  }
}

.row {
  grid-template-columns: 1fr 1fr;
  display: grid;
  /* row-gap: 1%; */
  column-gap: 1%;
  padding: 0 5px;
}

@media (max-width: 576px) {
  .row {
    grid-template-columns: 1fr;
    /* row-gap: 1%; */
    /* column-gap: 1%; */
  }
}

@media (min-width: 768px) {
  .row {
    grid-template-columns: 1fr;
    /* row-gap: 1%; */
    /* column-gap: 1%; */
  }
}

@media (min-width: 992px) {
  .row {
    grid-template-columns: 1fr;
    /* row-gap: 1%; */
    /* column-gap: 1%; */
  }
}

@media (min-width: 1200px) {
  .row {
    grid-template-columns: 1fr 1fr;
    /* row-gap: 1%; */
    /* column-gap: 1%; */
  }
}

@media (min-width: 768px) {
  .edu-col-sm-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 768px) {

  .edu-col-sm-1,
  .edu-col-sm-2,
  .edu-col-sm-3,
  .edu-col-sm-4,
  .edu-col-sm-5,
  .edu-col-sm-6,
  .edu-col-sm-7,
  .edu-col-sm-8,
  .edu-col-sm-9,
  .edu-col-sm-10,
  .edu-col-sm-11,
  .edu-col-sm-12 {
    float: left;
  }
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

@media (min-width: 1200px) {
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.card.card-custom>.card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

.card.card-custom>.card-header .card-title,
.card.card-custom>.card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}

.card.card-custom>.card-header .card-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}

.search-icon {
  /* border-bottom: 1px solid #60c2ba !important; */
  border: none;
  color: #3699ff;
  display: block;
  font-size: 1.08rem;
  line-height: 1.5;
  /* margin-top: 22px; */
}

.searchicon {
  border-bottom: 1px solid #60c2ba !important;
  border: none;
  color: #3699ff;
  display: block;
  font-size: 1.08rem;
  line-height: 1.5 !important;
  margin-top: 22px;
}

.topbar .topbar-item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.btn.btn-icon.btn-lg,
.btn-group-lg>.btn.btn-icon {
  height: calc(1.5em + 1.65rem + 2px);
  width: calc(1.5em + 1.65rem + 2px);
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.btn-clean {
  color: #b5b5c3;
  background-color: transparent;
  border-color: transparent;
}

.w-auto {
  width: auto !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.font-size-base {
  font-size: 1rem;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.btn-clean {
  color: #b5b5c3;
  background-color: transparent;
  border-color: transparent;
}

.btn:not(.btn-text) {
  cursor: pointer;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.font-size-base {
  font-size: 1rem;
}

.text-dark-50 {
  color: #7e8299 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.symbol.symbol-light-success .symbol-label {
  background-color: #c9f7f5;
  color: #1bc5bd;
}

@media (min-width: 992px) {
  .symbol.symbol-lg-35 .symbol-label {
    width: 35px;
    height: 35px;
  }
}

.ml-2p {
  margin-left: 3px;
}

.add-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-tel-input .form-control {
  display: block;
  padding-left: 46px !important;
  width: 100% !important;
  padding: 0.65rem 1rem;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #3f4254;
  background-color: #f3f6f9 !important;
  background-clip: padding-box !important;
  border: 0px solid #e4e6ef !important;
  appearance: none;
  border-radius: 0.42rem !important;
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 0px solid #cacaca !important;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #fff;
}

.country-code-feild {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  height: auto !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  outline: none;
}

.login.login-1 .login-form {
  width: 100%;
  max-width: 400px !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  /* width: 38px; */
  height: 100%;
  padding: 0 0 0 8px;
  /* border-radius: 3px 0 0 3px; */
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #f3f6f9 !important;
}

.sub-menu {
  margin-left: 25px;
}

.radio-btn {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.radio-input {
  margin-right: 10px;
}

.align-end-text {
  float: right !important;
}

input[type="radio"] {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
  margin-left: 1rem;
}

.ms-1rem {
  margin-left: 1rem;
}

.radio-sec {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio-btn-label {
  margin-right: 0.2rem;
}

.custom-radio {
  display: flex;
  align-items: center;
}

.RadioButton {
  margin: 16px 0;
}

.form-group .form-text {
  font-size: 0.9rem;
  font-weight: 400;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #b5b5c3;
}

.checkbox-inline {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-inline .checkbox {
  margin-right: 1rem;
  margin-bottom: 0.35rem;
}

.checkbox {
  font-size: 1rem;
  font-weight: 400;
  color: #3f4254;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin: 0;
}

.checkbox {
  margin-right: 10px;
}

.checkbox>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-inline .checkbox span {
  margin-right: 0.75rem;
}

.checkbox>span {
  background-color: #ebedf3;
  border: 1px solid transparent;
}

.checkbox>span {
  height: 18px;
  width: 18px;
}

.checkbox>span {
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0.42rem;
}

.checkbox>span:after {
  width: 5px;
  height: 10px;
}

.checkbox>span:after {
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 2px 2px 0
    /*rtl:ignore*/
    !important;
  transform: rotate(45deg)
    /*rtl:ignore*/
  ;
  margin-top: -2px;
}

.checkbox>input:checked~span {
  background-color: #3699ff;
}

.checkbox>input:checked~span {
  transition: all 0.3s ease;
  background-color: none;
}

.checkbox-inline .checkbox span {
  margin-right: 0.75rem;
}

.checkbox>span {
  background-color: #ebedf3;
  border: 1px solid transparent;
}

.checkbox>input:checked~span:after {
  border-color: #ffffff;
}

.checkbox>input:checked~span:after {
  display: block;
}

.fa.fa-times:before {
  content: "\f00d";
}

/* Login screen */

/* @media (min-width: 992px) and (max-width: 1199.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 600px;
  }
} */
.flex-row-auto {
  flex: 0 0 auto;
}

.bgi-size-cover {
  background-size: cover;
}

@media (min-width: 1200px) {
  .p-lg-10 {
    /* padding: 8.5rem !important; */
    padding-top: 8.5rem !important;
    padding-left: 8.5rem !important;
    padding-right: 8.5rem !important;
  }
}

.p-7 {
  padding: 1.75rem 1.75rem 1.75rem 1.75rem !important;
}

@media (min-width: 992px) {
  .p-7 {
    padding: 1.75rem 1.75rem 1.75rem 0rem !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}

.mt-30 {
  margin-top: 7.5rem !important;
}

@media screen and (max-width:600px) {
  .mt-30 {
    margin-right: 25px;
    margin-top: 1rem !important;
  }


}

@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}

.flex-column-auto {
  flex: none;
}

@media (min-width: 992px) {
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
}

/* .react-code-input>input :focus{
    text-align: center !important;
  } */
.justify-content-center {
  justify-content: center !important;
}

.login.login-1.login-signin-on .login-signin {
  display: block;
}

@media (min-width: 992px) {
  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }
}

@media (min-width: 992px) {
  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }
}

.form-group {
  margin-bottom: 1.75rem;
}

.form-control.form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.h-auto {
  height: auto !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

/* .form-control {
    display: block;
    width: 91%;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    appearance: none;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  } */
.form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.42rem;
  font-size: 1.08rem;
  border-radius: 0.42rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.65rem 3rem 0.65rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%233F4254%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.42rem;
  font-size: 1.08rem;
  border-radius: 0.42rem;
}

.h-vh {
  height: 98vh;
}

.table-header {
  background: white;
  padding: 1rem;
  margin: 15px;
}

.headline {
  font-weight: 600;
  font-size: 16px;
  flex-grow: 1;
  transition: none !important;
  box-sizing: border-box;
  cursor: pointer;
  list-style: none;
  font-family: Poppins, Helvetica, "sans-serif";
  line-height: 1.5;
  margin-left: 1rem !important;
  margin-right: 1rem;
}

.bg-wht {
  background: white;
}

.radio-btn-label {
  font-size: 1rem;
  font-weight: 400;
}

.edu-history {
  float: right;
  font-size: 1rem;
  font-weight: 400;
}

input[type="file"]::-webkit-file-upload-button {
  /* visibility: hidden; */
  display: none;
}

.show-header {
  display: none;
}

@media (max-width: 576px) {
  .show-header {
    display: block;
  }
}

@media only screen and (min-width: 576px) {
  .pc-form-control {
    width: 100%;
  }
}

/* @media only screen and (min-width: 768px) {
  .pc-form-control {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .pc-form-control {
    width:100%
  }
}
@media only screen and (min-width: 1200px) {
  .pc-form-control {
    width: 100%;
  }
}
@media only screen and (min-width: 1400px) {
  .pc-form-control {
    width: 100%;
  }
} */

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px;
}

.ml-rem {
  margin-left: 0.5rem;
}

.ms-2 {
  margin-left: 2px;
}

.ms-4 {
  margin-left: 4px;
}

.max-h-70px {
  /* width: -webkit-fill-available; */
  height: 70px;
}

.h-vh {
  height: 100vh;
}

.h-50px {
  height: 50px;
}

/* .stu-side-menu-bar{
  background: #1e1e2d;
    grid-area: sidebar;
    overflow-y: auto;
    -webkit-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
} */
.stu-side-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  position: relative;
}

/* .stu-side-menu-body {
} */
.stu-sidebar-img {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 30px;
}

.stu-logo-img {
  cursor: pointer;
  width: 75px;
  object-fit: contain;
  vertical-align: middle;
}

.stu-layouts {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
}

@media only screen and (max-width: 1200px) {
  .stu-layouts {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.stu-heder-split {
  display: contents;
}

.stu-main-split {
  background: #f3f4f6;
  grid-area: main;
  overflow-y: auto;
}

.stu-aside-split {
  display: contents;
}

.stu-head-area {
  background: #fff;
  grid-area: nav;
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
}

.stu-row {
  /* display: -webkit-flex; */
  display: flex;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.chip-make {
  /* border-radius: 1px solid; */
  /* display: inline-block; */
  padding: 0 25px;
  /* height: 50px; */
  font-size: 12px;
  line-height: 30px;
  border-radius: 25px;
}

.chip-approve-clr {
  background-color: #b6f8d9;
  color: #57ce96;
}

.chip-pending-clr {
  background-color: #f6f8b6;
  color: #d5d05e;
}

.chip-decline-clr {
  background-color: #f9c8d3;
  color: #fc528f;
}

.stu-logo-img {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

/* @media only screen and (max-width: 991px){
  .react-tel-input{
    width: 122% !important;
  }
  } */
.main-section {
  position: relative;
  width: 100%;
  margin: 0px;
  height: auto;
  background-color: #fff;
  padding: 15px 0.2% 15px 2.2%;
  /* padding-left: ; */
  /* padding-right: ; */
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgb(209 212 212 / 70%);
}

.edu-col-xs-12 {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.edu-row {
  margin-right: -15px;
  margin-left: -15px;
}

.edu-row::before {
  display: table;
  content: " ";
}

.edu-row::after {
  clear: both;
}

.edu-row::after {
  display: table;
  content: " ";
}

.edu-col-xs-12 {
  width: 100%;
}

.edu-col-xs-1,
.edu-col-xs-2,
.edu-col-xs-3,
.edu-col-xs-4,
.edu-col-xs-5,
.edu-col-xs-6,
.edu-col-xs-7,
.edu-col-xs-8,
.edu-col-xs-9,
.edu-col-xs-10,
.edu-col-xs-11,
.edu-col-xs-12 {
  float: left;
}

.edu-col-xs-1,
.edu-col-sm-1,
.edu-col-md-1,
.edu-col-lg-1,
.edu-col-xs-2,
.edu-col-sm-2,
.edu-col-md-2,
.edu-col-lg-2,
.edu-col-xs-3,
.edu-col-sm-3,
.edu-col-md-3,
.edu-col-lg-3,
.edu-col-xs-4,
.edu-col-sm-4,
.edu-col-md-4,
.edu-col-lg-4,
.edu-col-xs-5,
.edu-col-sm-5,
.edu-col-md-5,
.edu-col-lg-5,
.edu-col-xs-6,
.edu-col-sm-6,
.edu-col-md-6,
.edu-col-lg-6,
.edu-col-xs-7,
.edu-col-sm-7,
.edu-col-md-7,
.edu-col-lg-7,
.edu-col-xs-8,
.edu-col-sm-8,
.edu-col-md-8,
.edu-col-lg-8,
.edu-col-xs-9,
.edu-col-sm-9,
.edu-col-md-9,
.edu-col-lg-9,
.edu-col-xs-10,
.edu-col-sm-10,
.edu-col-md-10,
.edu-col-lg-10,
.edu-col-xs-11,
.edu-col-sm-11,
.edu-col-md-11,
.edu-col-lg-11,
.edu-col-xs-12,
.edu-col-sm-12,
.edu-col-md-12,
.edu-col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* .white-section {
  border-bottom: 1px solid #ededed;
  color: #6d6d6d;
  background-color: #fff;
} */
.blue-section,
.white-section {
  height: 60px;
  line-height: 60px;
  width: 100%;
}

.hand {
  cursor: pointer;
}

.text-semi-bold {
  font-weight: 600 !important;
}

@media (min-width: 1200px) {
  .edu-col-lg-11 {
    width: 91.66666667%;
  }
}

@media (min-width: 1200px) {

  .edu-col-lg-1,
  .edu-col-lg-2,
  .edu-col-lg-3,
  .edu-col-lg-4,
  .edu-col-lg-5,
  .edu-col-lg-6,
  .edu-col-lg-7,
  .edu-col-lg-8,
  .edu-col-lg-9,
  .edu-col-lg-10,
  .edu-col-lg-11,
  .edu-col-lg-12 {
    float: left;
  }
}

@media (min-width: 992px) {
  .edu-col-md-11 {
    width: 91.66666667%;
  }
}

@media (min-width: 992px) {

  .edu-col-md-1,
  .edu-col-md-2,
  .edu-col-md-3,
  .edu-col-md-4,
  .edu-col-md-5,
  .edu-col-md-6,
  .edu-col-md-7,
  .edu-col-md-8,
  .edu-col-md-9,
  .edu-col-md-10,
  .edu-col-md-11,
  .edu-col-md-12 {
    float: left;
  }
}

@media (min-width: 768px) {
  .edu-col-sm-10 {
    width: 83.33333333%;
  }
}

@media (min-width: 768px) {

  .edu-col-sm-1,
  .edu-col-sm-2,
  .edu-col-sm-3,
  .edu-col-sm-4,
  .edu-col-sm-5,
  .edu-col-sm-6,
  .edu-col-sm-7,
  .edu-col-sm-8,
  .edu-col-sm-9,
  .edu-col-sm-10,
  .edu-col-sm-11,
  .edu-col-sm-12 {
    float: left;
  }
}

.edu-col-xs-10 {
  width: 83.33333333%;
}

.edu-col-xs-1,
.edu-col-xs-2,
.edu-col-xs-3,
.edu-col-xs-4,
.edu-col-xs-5,
.edu-col-xs-6,
.edu-col-xs-7,
.edu-col-xs-8,
.edu-col-xs-9,
.edu-col-xs-10,
.edu-col-xs-11,
.edu-col-xs-12 {
  float: left;
}

.edu-col-xs-1,
.edu-col-sm-1,
.edu-col-md-1,
.edu-col-lg-1,
.edu-col-xs-2,
.edu-col-sm-2,
.edu-col-md-2,
.edu-col-lg-2,
.edu-col-xs-3,
.edu-col-sm-3,
.edu-col-md-3,
.edu-col-lg-3,
.edu-col-xs-4,
.edu-col-sm-4,
.edu-col-md-4,
.edu-col-lg-4,
.edu-col-xs-5,
.edu-col-sm-5,
.edu-col-md-5,
.edu-col-lg-5,
.edu-col-xs-6,
.edu-col-sm-6,
.edu-col-md-6,
.edu-col-lg-6,
.edu-col-xs-7,
.edu-col-sm-7,
.edu-col-md-7,
.edu-col-lg-7,
.edu-col-xs-8,
.edu-col-sm-8,
.edu-col-md-8,
.edu-col-lg-8,
.edu-col-xs-9,
.edu-col-sm-9,
.edu-col-md-9,
.edu-col-lg-9,
.edu-col-xs-10,
.edu-col-sm-10,
.edu-col-md-10,
.edu-col-lg-10,
.edu-col-xs-11,
.edu-col-sm-11,
.edu-col-md-11,
.edu-col-lg-11,
.edu-col-xs-12,
.edu-col-sm-12,
.edu-col-md-12,
.edu-col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.mandatory-red {
  color: #e64a4a;
  margin-left: 3px;
}

@media (min-width: 1200px) {
  .edu-col-lg-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 1200px) {

  .edu-col-lg-1,
  .edu-col-lg-2,
  .edu-col-lg-3,
  .edu-col-lg-4,
  .edu-col-lg-5,
  .edu-col-lg-6,
  .edu-col-lg-7,
  .edu-col-lg-8,
  .edu-col-lg-9,
  .edu-col-lg-10,
  .edu-col-lg-11,
  .edu-col-lg-12 {
    float: left;
  }
}

@media (min-width: 992px) {
  .edu-col-md-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 992px) {

  .edu-col-md-1,
  .edu-col-md-2,
  .edu-col-md-3,
  .edu-col-md-4,
  .edu-col-md-5,
  .edu-col-md-6,
  .edu-col-md-7,
  .edu-col-md-8,
  .edu-col-md-9,
  .edu-col-md-10,
  .edu-col-md-11,
  .edu-col-md-12 {
    float: left;
  }
}

@media (min-width: 768px) {
  .edu-col-sm-2 {
    width: 16.66666667%;
  }
}

@media (min-width: 768px) {

  .edu-col-sm-1,
  .edu-col-sm-2,
  .edu-col-sm-3,
  .edu-col-sm-4,
  .edu-col-sm-5,
  .edu-col-sm-6,
  .edu-col-sm-7,
  .edu-col-sm-8,
  .edu-col-sm-9,
  .edu-col-sm-10,
  .edu-col-sm-11,
  .edu-col-sm-12 {
    float: left;
  }
}

.edu-col-xs-2 {
  width: 16.66666667%;
}

.edu-col-xs-1,
.edu-col-xs-2,
.edu-col-xs-3,
.edu-col-xs-4,
.edu-col-xs-5,
.edu-col-xs-6,
.edu-col-xs-7,
.edu-col-xs-8,
.edu-col-xs-9,
.edu-col-xs-10,
.edu-col-xs-11,
.edu-col-xs-12 {
  float: left;
}

.edu-col-xs-1,
.edu-col-sm-1,
.edu-col-md-1,
.edu-col-lg-1,
.edu-col-xs-2,
.edu-col-sm-2,
.edu-col-md-2,
.edu-col-lg-2,
.edu-col-xs-3,
.edu-col-sm-3,
.edu-col-md-3,
.edu-col-lg-3,
.edu-col-xs-4,
.edu-col-sm-4,
.edu-col-md-4,
.edu-col-lg-4,
.edu-col-xs-5,
.edu-col-sm-5,
.edu-col-md-5,
.edu-col-lg-5,
.edu-col-xs-6,
.edu-col-sm-6,
.edu-col-md-6,
.edu-col-lg-6,
.edu-col-xs-7,
.edu-col-sm-7,
.edu-col-md-7,
.edu-col-lg-7,
.edu-col-xs-8,
.edu-col-sm-8,
.edu-col-md-8,
.edu-col-lg-8,
.edu-col-xs-9,
.edu-col-sm-9,
.edu-col-md-9,
.edu-col-lg-9,
.edu-col-xs-10,
.edu-col-sm-10,
.edu-col-md-10,
.edu-col-lg-10,
.edu-col-xs-11,
.edu-col-sm-11,
.edu-col-md-11,
.edu-col-lg-11,
.edu-col-xs-12,
.edu-col-sm-12,
.edu-col-md-12,
.edu-col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.collapse-img {
  background-image: url(/public/300_21.jpg);
  background-repeat: no-repeat;
  display: block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 30px;
}

.edu-row:after {
  clear: both;
}

.white-section,
.white-section,
.white-section {
  background-color: #46b8afdb;
  color: #ffffff;
  border-radius: 5px 5px 0px 0px;
}

.blue-section {
  color: #ffffff;
  background-color: #46b8afdb;
  border-radius: 5px 5px 0px 0px;
}

/* .white-section {
  border-bottom: 1px solid #ededed;
  color: #6d6d6d;
  background-color: #fff;
} */
.blue-section,
.white-section {
  height: 60px;
  line-height: 60px;
  width: 100%;
}

.bot-mar-20 {
  margin-bottom: 20px !important;
}

.light-blue-bg {
  background-color: #eff2f5;
  border-bottom: 1px solid #ededed;
}

/* .padding-bottom-10 {
  padding-bottom: 49px !important;
} */
.padding-top-10 {
  padding-top: 10px !important;
}

.margin-right-zero {
  margin-right: 0px !important;
}

.margin-left-zero {
  margin-left: 0px !important;
}

.side-bar-details {
  height: 100%;
  padding-left: 25px;
}

form[name="EducationForm"] .single-line {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold-text-color {
  color: #6d6d6d;
}

.text-bold {
  font-weight: 900 !important;
}

.font-14-regular {
  font-size: 14px;
  font-weight: normal;
}

.edit-cancel-position {
  position: relative;
  top: 40px;
  left: -10px;
}

.sprite-edit {
  width: 14px;
  height: 14px;
  background-position: -127px -65px;
}

.sprite,
.spritte {
  background-image: url(/public/300_21.jpg);
  background-repeat: no-repeat;
  display: block;
}

fieldset[disabled] div.reset-blue-text,
fieldset[disabled] span.spritte.sprite-cancel,
fieldset[disabled] div.break-edit,
fieldset[disabled] div.delete-emp {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

span[disabled].spritte.sprite-cancel {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.sprite-cancel {
  width: 14px;
  height: 14px;
  background-position: -152px -34px;
}

.sprite,
.spritte {
  background-image: url(/public/300_21.jpg);
  background-repeat: no-repeat;
  display: block;
}

.padd-left-right-35 {
  padding: 0 22px;
}

.padding-zero,
div.read {
  padding: 0;
}

.padding-zero,
div.read {
  padding: 0px !important;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.edit-delete-structure {
  padding-top: 10px;
  margin-bottom: -15px;
  border-bottom: 1px solid #ececec;
}

.edit-delete-text {
  font-size: 0.75em;
  background-color: #fafafa;
  color: #0bbff2;
  padding-top: 7px;
  text-align: center;
  padding-bottom: 6px;
}

.edit-delete-border {
  border-right: 1px solid #e1e1e1;
}

.edu-col-xs-6 {
  width: 50%;
}

div.horizontal-center>div.edit-pencil.inline-block {
  position: relative;
  top: 3px;
}

.edit-pencil {
  height: 15px;
  width: 15px;
  content: "";
  background: url(/public/300_21.jpg) -132px -65px no-repeat;
}

.inline-block {
  display: inline-block;
}

.inline-display {
  display: inline-block;
  padding-left: 5px;
}

div.horizontal-center>div.delete-cross.inline-block {
  position: relative;
  top: 3px;
}

.delete-cross {
  height: 15px;
  width: 15px;
  content: "";
  background: url(/public/300_21.jpg) -158px -34px no-repeat;
}

.edit-delete-structure {
  padding-top: 10px;
  margin-bottom: -15px;
  border-bottom: 1px solid #ececec;
}

.expand-section {
  top: 25px;
  position: relative;
}

.white-section:hover .sprite-add,
.white-section:focus .sprite-add,
.white-section:focus:hover .sprite-add {
  background-position: -152px -5px;
}

.sprite-add {
  width: 10px;
  height: 10px;
  background-position: -132px -5px;
}

.section-sub-heading {
  color: #686868;
  padding: 14px 26px;
  font-weight: 600;
  font-size: 1em;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

@media (min-width: 768px) {
  .edu-col-sm-8 {
    width: 66.66666667%;
  }
}

@media (min-width: 768px) {
  .edu-col-sm-4 {
    width: 33.33333333%;
  }
}

.hidden-file-upload {
  display: none !important;
}

input[type="file"] {
  display: block;
}

@media (min-width: 768px) {
  .edu-col-sm-11 {
    width: 91.66666667%;
  }
}

.edu-btn-primary-outline.disabled,
.edu-btn-primary-outline[disabled] {
  background-color: #ffffff;
  color: #18bef0;
  border: 1px solid #0bbff2;
  opacity: 0.5;
}

.edu-btn-primary.disabled,
.edu-btn-primary[disabled] {
  background-color: #0bbff2;
  border: 1px solid #0bbff2;
  opacity: 0.5;
}

.edu-btn.disabled,
.edu-btn[disabled],
fieldset[disabled] .edu-btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

.edu-btn-primary-outline {
  background-color: #ffffff;
  color: #18bef0;
  border: 1px solid #0bbff2;
}

.edu-btn-primary {
  background-color: #0bbff2;
  border: 1px solid #0bbff2;
}

.edu-btn {
  transition: all 0.5s;
  border-radius: 5px;
  font-size: 1.125em;
  font-weight: 900;
  color: #fff;
  padding: 11px 10px;
}

.edu-btn-block {
  display: block;
  width: 100%;
}

.edu-btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

@media (min-width: 992px) {
  .edu-col-md-6 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .edu-col-sm-6 {
    width: 50%;
  }
}

.top-mar-15 {
  margin-top: 15px !important;
}

.message-alert>.uploaded-file {
  background-color: #f2f6f9;
  font-size: 1em;
  font-weight: 100;
  box-shadow: none;
  line-height: 16px;
  display: table;
  text-align: left;
  padding: 10px 35px 9px 73px;
  border: 1px solid #ddd;
  pointer-events: all;
}

.message-alert-common,
.message-alert>.green,
.message-alert>.yellow,
.message-alert>.red,
.message-alert>.uploaded-file {
  position: relative;
  width: 100%;
  min-height: 39px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgb(209 212 212 / 70%);
  padding: 9px 35px 9px 70px;
  text-align: center;
  pointer-events: none;
}

.message-alert>.uploaded-file:before {
  background: #eaeef1 url(../assets/Images/atch.png) no-repeat center;
  border-right: 1px solid #ddd;
}

.message-alert-common:before,
.message-alert>.green:before,
.message-alert>.yellow:before,
.message-alert>.red:before,
.message-alert>.uploaded-file:before {
  position: absolute;
  text-align: center;
  padding: 7px 1.5% 7px 1.5%;
  width: 65px;
  height: 100%;
  left: 0px;
  top: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  content: "";
  pointer-events: none;
}

.message-alert>.uploaded-file .file-name {
  color: #0bbff2;
  cursor: pointer;
  float: left;
}

.message-alert>.uploaded-file {
  background-color: #f2f6f9;
  font-size: 1em;
  font-weight: 100;
  box-shadow: none;
  line-height: 16px;
  display: table;
  text-align: left;
  padding: 10px 35px 9px 73px;
  border: 1px solid #ddd;
  pointer-events: all;
}

.message-alert-common,
.message-alert>.green,
.message-alert>.yellow,
.message-alert>.red,
.message-alert>.uploaded-file {
  position: relative;
  width: 100%;
  min-height: 39px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgb(209 212 212 / 70%);
  padding: 9px 35px 9px 70px;
  text-align: center;
  pointer-events: none;
}

.message-alert>.uploaded-file .file-size {
  color: #929292;
  float: right;
  padding-right: 20px;
  display: inline-block;
}

.message-alert>.uploaded-file .file-cross {
  height: 14px;
  width: 14px;
  position: absolute;
  float: right;
  right: 12px;
  top: 12px;
  cursor: pointer;
  z-index: 500;
  background: #f2f6f9 url(../assets/Images/atch.png) no-repeat center;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

.message-alert-common:after,
.message-alert>.green:after,
.message-alert>.yellow:after,
.message-alert>.red:after,
.message-alert>.uploaded-file:after {
  position: absolute;
  cursor: pointer;
  height: 39px;
  padding: 7px 15px 7px 10px;
  text-align: center;
  right: 5px;
  top: 0px;
  content: "";
  pointer-events: all;
}

.document-delete:after {
  cursor: default !important;
}

.edu-mark {
  font-weight: 600 !important;
  /* color: #6d6d6d; */
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.42857143;
}

.edu-col-lg-10>div {
  padding: 3px;
  font-size: 16px;
}

.fs-400 {
  font-weight: 400 !important;
  /* font-style: 16px; */
}

.fs-500 {
  font-weight: 500 !important;
  /* font-style: 16px; */
}

.pc-mr-5 {
  margin-right: "5px";
}

.eduImage {
  background: "#C9FCFA";
  justify-content: "center";
  display: "flex";
  align-items: "center"
}



.login-button-sty-button {
  margin-left: 30px;
}

@media screen and (max-width:1200px) {
  .login-button-sty-button {
    margin-left: 30px;
  }
}

@media screen and (max-width:991px) {
  .login-button-sty-button {
    margin-left: 15px;
  }
}

@media screen and (max-width:400px) {
  .login-button-sty-button {
    margin-left: 15px;
  }
}

@media screen and (min-width:1400px) {
  .select-signup-type-button {
    width: 114%;
  }
}

@media screen and (min-width:992px) {
  .select-signup-type-button {
    width: 112%;
  }
}

@media screen and (max-width:991px) {
  .select-signup-type-button {
    width: 110%;
  }
}

@media screen and (max-width:986px) {
  .resp-padding {
    padding: "5px";
  }
}

#multiSelect .searchWrapper{
  padding: 18px !important;
}