@tailwind base;
@tailwind components;
@tailwind utilities;





@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

:root {
  --primary-color: red;
  --secounary-color: rgba(88, 88, 88, 0.53);
  --sidebar-link-color-active: #fff;
  --sidebar-link-background-active: #000000;
  --sidebar-link-icon-active: #000000;
  --datatable-border-bottom: #000000;
  --sidebar-background: #000;
}

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--secounary-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 13px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 50px;
}

.charts__left {
  padding: 25px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  border: 1px solid #f5f5f5;
}

.charts__left:hover,
.charts__right:hover {
  box-shadow: 5px 5px 13px #bcb9b9, -5px -5px 13px #ffffff;
  transition: 0.8s;
}

.charts__left__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__left__title>div>h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__left__title>div>p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__left__title>i {
  color: #ffffff;
  font-size: 20px;
  background: #ffc100;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right {
  padding: 25px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  border: 1px solid #f5f5f5;
}

.charts__right__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__right__title>div>h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__right__title>div>p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__right__title>i {
  color: #ffffff;
  font-size: 20px;
  background: #39447a;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card3 {
  background: #d6d8d9;
  color: #3a3e41;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

/*  SIDEBAR STARTS HERE  */

#sidebar {
  background: var(--sidebar-background);
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}

.sidebar__img {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 30px;
}

.sidebar__title>div>img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title>div>h1 {
  font-size: 18px;
  display: inline;
  color: #ffffff;
}

.sidebar__title>i {
  font-size: 18px;
  display: none;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.sidebar__link>a {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__routes {
  transition: 1s;
  background-color: transparent;
}

.sidebar__routes:hover,
.sidebar__routes.active {
  background-image: linear-gradient(to right,
      transparent,
      var(--sidebar-link-background-active),
      transparent) !important;
  color: var(--sidebar-link-color-active);
  -webkit-transition: background-image 500ms linear;
  -ms-transition: background-image 500ms linear;
  transition: background-image 500ms linear;
}

.sidebar__routes:hover span,
.sidebar__routes.active span {
  color: #ffffff !important;
  transition: 500ms;
}

.sidebar__routes:hover svg,
.sidebar__routes.active svg {
  color: var(--sidebar-link-icon-active) !important;
  transition: 500ms;
}

.sidebar__routes span,
.sidebar__routes svg {
  color: #9899ac !important;
  transition: 500ms;
  font-size: 13px !important;
}

.sidebar__link>i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout>a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout>i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

.datatable_action_icons {
  font-size: larger;
  cursor: pointer;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  line-height: 2;
}

.datatable-action-btn {
  border: none;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: var(--primary-color) !important;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #585858;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: var(--primary-color) !important;
  border: 1px solid transparent;
  background-color: #585858;
  background: transparent;
  border-radius: 5px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  font-size: 14px;
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid transparent;
  border-bottom: 1px solid #ccc;
  border-radius: 3px;
  padding-left: 20px;
}

.dataTables_wrapper .dataTables_filter::before {
  content: "\f002";
  color: #000;
  margin-right: -15px;
  font: normal normal normal 14px/1 FontAwesome;
}

.dataTables_filter input:focus-visible,
.dataTables_wrapper .dataTables_length select:focus-visible {
  outline: none;
}

.dataTables_wrapper .dataTables_length select {
  border: 1px solid transparent;
  margin: auto 10px;
}

table.dataTable.no-footer {
  margin-bottom: 15px;
}

.parking-menu li {
  list-style: none;
}

/* .volumn-chart{
  margin-left: 50px;
  background-color: white;
  padding-top: 70px;
} */
/* .booking-input:focus{
  border: 1px solid black!important;
} */
.booking-img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  text-align: center;
  left: 30px;
  position: relative;
  top: 25px;
}

.booking-img img {
  position: relative;
  top: -17px;
}

.booking-model input[type=text] {
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
}

.booking-model input[type=time] {
  width: 145px;
}

.booking-model input[type=time],
input[type=date] {
  border-radius: 5px;
  border: 1px solid lightgray
}

input[type="text"]:focus {
  border-color: black !important;
}

.model-input {
  padding-top: 20px;
}

.chart-sub-title h5 {
  color: black;
  position: relative;
  text-align: center;
  top: -20px;
}

table.dataTable.no-footer {
  border-bottom: 1px solid var(--datatable-border-bottom);
}

@media only screen and (max-width: 855px) {
  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }

  .statistics-card {
    width: 100%;
  }

  .booking-card {
    width: 100%;
  }

  .add-booking {
    width: 100%;
  }
}

.offer-flag {
  background: red;
  color: white !important;
  border-radius: 5px;
  padding: 0 5px;
  margin: 2px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
  font-size: 10px;
}

/* .dashboard-card-animate {
  animation: my-animation 5s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 25%);
  background-size: 1000px 100%;
}

@keyframes my-animation {
  from{
    background-position: -1000px 0;
  }
  to{
    background-position: 1000px 0;
  }

} */

.variant-proportion-button-div {
  cursor: pointer;
}

.variant-proportion-button-div-disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
}

.home_parent {
  width: 100%;
  height: 100vh;
  display: table;
  text-align: center;
}

.home_parent>.child {
  display: table-cell;
  vertical-align: middle;
  font-size: 30px;
}

.MuiDialog-paper {
  min-width: 50vw !important;
}


@media only screen and (max-width: 1200px) and (min-width: 420px) {

  .statistics-card {
    width: 100%;
  }

  .statistics {
    width: 100%;
  }
}


.alignCenter {
  display: flex;
  align-items: center;
}

.alignItemCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justContentright {
  display: flex;
  justify-content: right;
}

.justContentCenter {
  display: flex;
  justify-content: center;
}

.bookingStatisChart .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.groubButton button {
  margin: 2px;

}

.today button {
  font-size: 18px;
}

.bookingDetails .bookingDetails_input {
  margin-top: 30px;
}

.errMsg {
  color: red;
}

.bg-day {
  background-color: #7e8299 !important;
  color: white !important;
}

.bg-month {
  background-color: #7e8299 !important;
  color: white !important;
}

.bg-week {
  background-color: #7e8299 !important;
  color: white !important;
}

.dr {
  padding-left: 20px;
}

._3n {
  width: 135px !important;
}

._1g {
  font-size: 50px;
  height: 30px;
  align-items: center;
  display: flex;

}

.Tx {
  height: 100px;
}

.Lx {
  font-size: 30px !important;
  height: 30px;
  align-items: center;
  display: flex;
  margin-bottom: 15px !important;
}

.jb {
  font-size: 25px !important;
}

.keyName {
  font-size: 16px;
  color: gray;
}

.keyValue {
  font-size: 16px;
  /* font-weight: bold; */
}

.dtr-title {
  font-weight: bold;
  margin-right: 10px;
}

.printscrn-table-font, .printscrn-table-font th, .printscrn-table-font td{
  border: 1px solid #000;
  border-collapse: collapse;
  padding: 5px;
}

.customTable{
  font-size: 10px !important;
}

.customTable td{
  padding: 10px !important;
}


.printscrn-table-font {
  width: 100%;
  text-align: center;
}

/* Print Screen Style Start */
.print-icon {
  display: flex;
  justify-content: end;
}

.print-screen-clr {
  color: #009CBE;
  font-size: 12px;
}

.printscrn-checkbox-lable {
  font-size: 10px;
}

.printscrn-table-font {
  font-size: 9px;
}

.printscrn-text {
  font-size: 10px;
}

.printscrn-h2 {
  font-size: 15px;
}

.printscrn-h3 {
  font-size: 13px;
}
.grid-padding-control{
  padding-top: 0px !important;
}

.table_head{
  color: gray;
}

.table_value{
  font-weight: bold;
}

.css-19vroi4-MuiPaper-root-MuiDialog-paper{
  max-width: 100% !important;
}

.css-1uuyzc5{
  max-width: 100% !important;
}

.details_div {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.garyClass {
  background: rgb(163, 170, 163) !important;
  color: white;
}

.report-card{
  color:white;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.report-card-title{
  text-align: center;
  color:black;
  font-size: 18px;
}
/* reports */


.text-data{
  text-align: center;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}
.seperate-page{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    margin:20px 0px;
}
.box-booking{
    padding: 10px 50px;
    border-radius: 20px;
    border: 1px solid black;
    box-shadow: 2px 2px gray;
}
.one{
  background:linear-gradient(30deg,#d0edf5,#00eeff);
}
.two{
  background: linear-gradient(30deg,#fc9167,#ef6338);
}
.three{
  background: linear-gradient(30deg,#42eb7a,#36f710)
}
.active-color{
  background: linear-gradient(30deg,#f0dfe1,#d5ab7b)
}
.inactive-color{
  background: linear-gradient(30deg,#cfecd9,#ae10f7)
}

.luggage .css-14lo706>span{
  color: white;
  opacity: 2;
}

.diasable{
  color: gray;
}

.status-column{
  display: flex;
  justify-content: center;
}

.status-column-out{
  display: flex;
  justify-content: center;
  padding-top: 20px !important;
}

.trip-status-column-out{
  display: flex;
  justify-content: center;
  padding-top: 11px !important;
}

.filter-date .css-1duw4dn-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline
{
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.filter-date legend span ,.filter-date .css-jc3hri-MuiFormLabel-root-MuiInputLabel-root.Mui-error{
  color: gray !important;
}


