@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 20px;
    height: 20px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    /* position: relative; */
  }
  
  .spinner-container {
    /* display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50px, -50px);
    background: white;
    padding: 10px;
    border-radius: 5px */

    background-color: rgba(16, 16, 16, 0.356);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}